import Swiper from "swiper/bundle";
import AOS from "aos";
import moment from "moment";
// import styles bundle
import "swiper/css/bundle";

require("moment-timezone");

jQuery(document).ready(function ($) {
    $(".datepicker").datepicker({
        beforeShow: function (input, inst) {
            $("#ui-datepicker-div").addClass("exceptions-datepicker");
        },
        dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
        dateFormat: "dd/mm/yy",
        changeMonth: true,
        changeYear: true,
        yearRange: "-00:+03", // Adjust date format as needed
    });

    //aos settings global
    AOS.init({
        duration: 1200, // values from 0 to 3000, with step 50ms
        easing: "ease",
        offset: 150,
        // disable: 'mobile',
        disable: function () {
            var maxWidth = 1160;
            return window.innerWidth < maxWidth;
        },
    });

    // SWIPER SLIDERS

    //quotes carousel
    const quotes = document.querySelectorAll(".quotes-wrapper");
    var i = 0;

    for (i = 0; i < quotes.length; i++) {
        var first = quotes[i].nextElementSibling;

        quotes[i].classList.add("quotes-wrapper-" + i); //slider
        first.classList.add("quotes-pagination-" + i); //pagination

        var quotesSlides = new Swiper(".quotes-wrapper-" + i, {
            speed: 1000,
            direction: "horizontal",
            effect: "fade",
            allowTouchMove: false,
            fadeEffect: {
                crossFade: true,
            },
            autoplay: true,
            loop: true,
            slidesPerView: 1,
            pagination: {
                el: ".quotes-pagination-" + i,
                type: "bullets",
                clickable: true,
            },
        });
    }

    //menu
    $("body").on("click", ".mobileMenu .contain", function () {
        $(".menu .content").show();
        $("#page").toggleClass("mobileOpen");
        $(".menu .content").toggleClass("open");
        $(".menuContainer").toggleClass("active");
        $(".menuContainer .menu").toggleClass("show");

        $("body").toggleClass("hidden");

        if ($("#page").hasClass("mobileOpen")) {
            $("#Line_971").css("stroke", "#001121");
            $(".ellipseHide").hide();
            $("#animatedlogo").css("z-index", "999");
            $("#footer").css("z-index", "0");
        } else {
            $("#Line_971").css("stroke", "#fff");
            $(".ellipseHide").show();
            $("#animatedlogo").css("z-index", "0");
            $("#footer").css("z-index", "101");
        }

        $(".logo svg").toggleClass("openNav");
        $("#animatedLogoSVGText").toggleClass("openNav");

        $(this).parent().toggleClass("open");
    });

    if ($(".whiteOverlay").length) {
        var scrolltop = $(window).scrollTop();
        var distance = $("#animateSpace").offset().top;
        var height = $("#animateSpace").height();

        var triggerPoint = distance + height;

        if (scrolltop >= triggerPoint) {
            $(".whiteOverlay").addClass("fixed");
        } else {
            $(".whiteOverlay").removeClass("fixed");
        }
    }

    if ($(".whiteOverlayPage").length) {
        var scrolltop = $(window).scrollTop();
        var triggerPoint = $("#animateSpacePage").height();

        if (scrolltop >= triggerPoint) {
            $(".whiteOverlayPage").addClass("fixed");
        } else {
            $(".whiteOverlayPage").removeClass("fixed");
        }
    }

    if ($(window).scrollTop() != 0) {
        $("#animatedLogoSVG:not(.constantAnimated)").addClass("animated");
        $("#animatedLogoSVGText:not(.constantAnimated)").addClass("animated");
        $(".desktopNav").not(".noanimate").addClass("up");
        $("#nav-icon").not(".noanimate").addClass("up");
        $("#homeLogo").addClass("move");
        $("#svgtextgroup").css("transform", "translate(1px, 0px)");
        $("#svgtextgroup").css("transition", "0s");
    } else {
        $("#animatedLogoSVG:not(.constantAnimated)").addClass("transition");
        $("#animatedLogoSVGText:not(.constantAnimated)").addClass("transition");
        $("#nav-icon").not(".noanimate").addClass("transition");
        $(".desktopNav").not(".noanimate").addClass("transition");
        $("#homeLogo").addClass("transition");
        $("#svgtextgroup").css("transform", "translate(-32px, 0px)");
    }

    $(window).scroll(function (event) {
        $("#animatedLogoSVG:not(.constantAnimated)").addClass("transition");
        $("#animatedLogoSVGText:not(.constantAnimated)").addClass("transition");
        $("#nav-icon").not(".noanimate").addClass("transition");
        $(".desktopNav").not(".noanimate").addClass("transition");
        $("#homeLogo").addClass("transition");

        if ($(".whiteOverlay").length) {
            var scrolltop = $(window).scrollTop();
            var distance = $("#animateSpace").offset().top;
            var height = $("#animateSpace").height();

            var triggerPoint = distance + height;

            if (scrolltop >= triggerPoint) {
                $(".whiteOverlay").addClass("fixed");
            } else {
                $(".whiteOverlay").removeClass("fixed");
            }
        }

        if ($(".whiteOverlayPage").length) {
            var scrolltop = $(window).scrollTop();
            var triggerPoint = $("#animateSpacePage").height();

            if (scrolltop >= triggerPoint) {
                $(".whiteOverlayPage").addClass("fixed");
            } else {
                $(".whiteOverlayPage").removeClass("fixed");
            }
        }

        var scroll = $(window).scrollTop();

        if (scroll > 0) {
            $("#homeLogo").addClass("move");
            $("#animatedLogoSVG").addClass("animated");
            $("#animatedLogoSVGText").addClass("animated");
            $("#svgtextgroup").css("transform", "translate(1px, 0px)");
            $("#svgtextgroup").css("transition", "0.4s ease-in-out");
        } else {
            $("#homeLogo").removeClass("move");
            $("#animatedLogoSVG:not(.constantAnimated)").removeClass(
                "animated"
            );
            $("#animatedLogoSVGText:not(.constantAnimated)").removeClass(
                "animated"
            );
            $("#svgtextgroup").css("transform", "translate(-32px, 0px)");
        }

        if ($(".mobileMenu").hasClass("open")) {
        } else {
            if (scroll > 0) {
                $(".desktopNav").not(".noanimate").addClass("up");
                $("#nav-icon").not(".noanimate").addClass("up");

                // $('.mobileMenu').show();
            } else if (scroll == 0) {
                $("#nav-icon").removeClass("up");
                setTimeout(function () {
                    $(".desktopNav").not(".noanimate").removeClass("up");
                }, 200);
            }
        }
    });

    //close menu for desktop if resize is bigger than breakpoint
    // $(window).on('resize', function(){
    // 	var win = $(this); //this = window

    // 	if (win.width() > 1200) {

    // 		$('.menu .content.open').hide();

    // 		$('#page').removeClass('mobileOpen');
    // 		$('.menu .content').removeClass('open');
    // 		$('.menuContainer').removeClass('active');
    // 		$('.menuContainer .menu').removeClass('show');

    // 		$('.mobileMenu').removeClass('open');

    // 	}

    // });

    autoPlayYouTubeModal();

    function autoPlayYouTubeModal() {
        var trigger = $(".videoModalTriger");
        trigger.click(function () {
            var theModal = $(this).data("bs-target");
            var videoSRC = $(this).attr("data-videoModal");

            var videoSRCauto =
                videoSRC +
                "?autoplay=1&mute=0&color=white&controls=0&modestbranding=1&playsinline=1&rel=0&enablejsapi=1";
            $(theModal + " iframe").attr(
                "allow",
                "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            );
            $(theModal + " iframe").attr("src", videoSRCauto);

            $(theModal).on("hidden.bs.modal", function (e) {
                $(theModal + " iframe").attr("src", "");
            });
        });
    }

    $("body").on("click", "#forgotPasswordModal .closeBtn", function (event) {
        event.preventDefault();

        setTimeout(function () {
            $("#auth-password-reset-email").val("");
            $(".ajaxError").empty();
            $(".ajaxSuccess").empty();
        }, 500);
    });

    $("body").on("click", ".lostpassword-button", function (event) {
        event.preventDefault(event);
        const email = $("#auth-password-reset-email").val();
        const action = "authResetPassword";

        $(".ajaxSuccess").empty();

        var has_error = false;

        if (email.length <= 0) {
            // TODO
            has_error = true;
            var error = "The email/username field must not be blank";
        }

        if (has_error == true) {
            // TODO
            $(".ajaxError").html(error);
            return false;
        }

        $.ajax({
            type: "POST",
            url: site_data.adminajax,
            data: {
                action: action,
                email: email,
            },
            dataType: "json",
            encode: true,
        })
            .done(function (response) {
                if (response.status == false) {
                    $(".ajaxError").html(response.message);
                } else {
                    $(".ajaxError").empty();
                    $(".ajaxSuccess").html(response.message);
                    $("#auth-password-reset-email").val("");
                }
            })
            .fail(function () {})
            .always(function () {});

        return false;
    });

    $("body").on("click", ".bookSession", function (event) {
        event.preventDefault();

        var postID = $(this).attr("data-id");

        $.ajax({
            type: "POST",
            url: site_data.adminajax,
            data: {
                action: "registerSession",
                postID: postID,
            },
            dataType: "json",
            encode: true,
            beforeSend: function () {
                console.log(postID);
            },
        })
            .done(function (response) {
                if (response.status == false) {
                    //failed
                    console.log("failed");
                } else {
                    //success
                    location.reload(true);
                }
            })
            .fail(function () {})
            .always(function () {});
    });

    $("body").on("click", ".sessionTab", function (event) {
        event.preventDefault();
        var filter = $(this).attr("data-slug");

        $(".tab").removeClass("active");
        $(this).addClass("active");

        if (filter == "all") {
            $(".sessionRow").fadeOut();
            $(".sessionRow")
                .promise()
                .done(function () {
                    $(".sessionRow").removeClass("show");
                    $(".sessionRow").fadeIn().addClass("show");
                });
        } else {
            $(".sessionRow").fadeOut();
            $(".sessionRow")
                .promise()
                .done(function () {
                    $(".sessionRow").removeClass("show");
                    $(".sessionRow." + filter)
                        .fadeIn()
                        .addClass("show");
                });
        }
    });

    $("body").on(
        "click",
        ".admin-dashboard-training #complete .session-pagination-container a",
        function (event) {
            event.preventDefault();

            var url = new URLSearchParams(window.location.search);
            var filter = null;

            if (url.has("filter")) {
                filter = url.get("filter");
            }

            var page = this.children[0].innerText;

            if (page == "") {
                if ($(this).hasClass("next")) {
                    page = $(".page-numbers.current>span").text();
                    page = parseInt(page) + 1;
                }
                if ($(this).hasClass("prev")) {
                    page = $(".page-numbers.current>span").text();
                    page = parseInt(page) - 1;
                }
            }

            $.ajax({
                type: "POST",
                url: site_data.adminajax,
                data: {
                    action: "session_ajax_pagination",
                    nonce: site_data.nonce,
                    page: page,
                    filter: filter,
                },
                success: function (response) {
                    $(".ajax-response").html(response.data.output.rows);
                    $(".pagination-ajax").html(response.data.output.pagination);
                },
            });
        }
    );

    $("body").on("change", "#timezoneSelection", function (event) {
        event.preventDefault();

        var timezone = $(this).val();

        $("div.bookedSession .dates").each(function () {
            var datetime = $(this).attr("data-utc");
            var utcTime = moment.utc(datetime, "ddd, DD MMM YYYY HH:mm:ss Z");
            var covertTime = utcTime.tz(timezone);

            var newDay = covertTime.format("dddd Do MMMM YYYY");
            var newTime = covertTime.format("h:mm a z");

            $(this).empty();
            $(this).html(
                "<p>" +
                    newDay +
                    "</p><ul class='sessiontable mb-5'><li>" +
                    newTime +
                    "</li></ul>"
            );
        });

        $("div.conversionDateTime").each(function () {
            var datetime = $(this).attr("data-utc");
            var utcTime = moment.utc(datetime, "ddd, DD MMM YYYY HH:mm:ss Z");
            var covertTime = utcTime.tz(timezone);

            var newDay = covertTime.format("dddd Do MMMM YYYY");
            var newTime = covertTime.format("h:mm a z");

            $(this).empty();
            $(this).html(
                "<p>" +
                    newDay +
                    "</p><ul class='sessiontable'><li>" +
                    newTime +
                    "</li></ul>"
            );
        });

        $("div.modalDateConversion").each(function () {
            var datetime = $(this).attr("data-utc");
            var utcTime = moment.utc(datetime, "ddd, DD MMM YYYY HH:mm:ss Z");
            var covertTime = utcTime.tz(timezone);

            var newDay = covertTime.format("dddd Do MMMM YYYY");
            var newTime = covertTime.format("h:mm a z");

            $(this).empty();
            $(this).html(newDay + "<br>" + newTime);
        });
    });

    //add button to add new exception date field

    $("body").on("click", "[data-tessellate-plus]", function (event) {
        event.preventDefault();
        var count = $("#exception_dates [data-tessellate-plus]").length;
        count++;
        var input =
            '<input placeholder="dd/mm/yy" class="datepicker" type="text" id="date' +
            count +
            '" name="dates[]" value="">';

        var newElement =
            '<div class="d-flex align-items-center justify-content-start mb-2">' +
            input +
            '<span data-tessellate-plus class="icon ms-1 d-flex d-flex align-items-center justify-content-center"><svg xmlns="http://www.w3.org/2000/svg" width="17.853" height="17.853" viewBox="0 0 17.853 17.853"><g id="Group_233" data-name="Group 233" transform="translate(1 8.927) rotate(-45)"><line id="Line_105" data-name="Line 105" x2="11.21" y2="11.21" transform="translate(0 0)" fill="none" stroke="#001121" stroke-width="2"/><line id="Line_106" data-name="Line 106" y1="11.21" x2="11.21" transform="translate(0 0)" fill="none" stroke="#001121" stroke-width="2"/></g></svg></span><span data-tessellate-minus class="icon ms-1 d-flex d-flex align-items-center justify-content-center"><svg xmlns="http://www.w3.org/2000/svg" width="17.853" height="17.853" viewBox="0 0 17.853 17.853"><g id="Group_233" data-name="Group 233" transform="translate(1 8.927) rotate(-45)"><line id="Line_105" data-name="Line 105" x2="11.21" y2="11.21" transform="translate(0 0)" fill="none" stroke="#001121" stroke-width="2" /></g></svg></span></div>';

        // $("#exception_dates").append(newElement);

        $(newElement).insertAfter($(this).closest("div"));

        $(".datepicker").datepicker({
            beforeShow: function (input, inst) {
                $("#ui-datepicker-div").addClass("exceptions-datepicker");
            },
            dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
            dateFormat: "dd/mm/yy",
            changeMonth: true,
            changeYear: true,
            yearRange: "-00:+03", // Adjust date format as needed
        });
    });

    $("body").on("click", "[data-tessellate-minus]", function (event) {
        event.preventDefault();
        var prevElement = $(this).parent();
        prevElement.remove();
    });
});
